import ardoiseImg from "~/assets/images/supports/ardoise.jpg";
import bacAcierImg from "~/assets/images/supports/bacacier.jpg";
import fibrocimentImg from "~/assets/images/supports/fibrociment.jpg";
import jointDeboutImg from "~/assets/images/supports/joint_debout.jpg";
import bitumeImg from "~/assets/images/supports/membrane_bitume.jpg";
import membraneEpdmImg from "~/assets/images/supports/membrane_epdm.jpg";
import membranePvcImage from "~/assets/images/supports/membrane_pvc.jpg";
import membraneTpoImg from "~/assets/images/supports/membrane_tpo.jpg";
import ombrieresImg from "~/assets/images/supports/ombrieres.jpg";
import systemesLestesImg from "~/assets/images/supports/systemes_lestes.jpg";
import toleOnduleeImg from "~/assets/images/supports/tole_ondulee.jpg";
import tuilesCanalImg from "~/assets/images/supports/tuiles_canal.jpg";
import tuilesMecaniquesImg from "~/assets/images/supports/tuiles_mecaniques.jpg";
import tuilesPlatesImg from "~/assets/images/supports/tuiles_plates.jpg";

// List of all characteristics sorted by categories (we use this static data because API dont return characteristics categories)
const staticCharacteristicsDataWithoutSupports = {
  currently: {
    characteristicCategoryId: "currently",
    title: "En ce moment",
    param: "filtres",
    characteristics: [
      {
        technicalName: "properties60",
        slug: "en-promo",
        title: "En promo",
        type: "boolean",
      },
      {
        technicalName: "properties56",
        slug: "fin-de-serie",
        title: "Fin de série",
        type: "boolean",
      },
      {
        technicalName: "properties59",
        slug: "nouveau",
        title: "Nouveau",
        type: "boolean",
      },
    ],
  },
  specifications: {
    characteristicCategoryId: "specifications",
    title: "Caractéristiques",
    param: "filtres",
    characteristics: [
      // Sliders
      {
        technicalName: "power",
        slug: "puissance",
        title: "Puissance",
        type: "value",
        unit: {
          default: "W",
          "kits-solaires": "kWh",
          "modules-photovoltaiques": "Wc",
          "onduleurs-solaires": "kVA",
          "batteries-solaires": "kWh",
          "coffrets-electriques": "kVA",
          "e-mobilite": "kVA",
        },
      },
      {
        technicalName: "capaciteBatterie",
        slug: "capacite",
        title: "Capacité",
        type: "value",
        unit: {
          default: "kWh",
        },
      },
      {
        technicalName: "height",
        slug: "hauteur",
        title: "Hauteur",
        type: "value",
        unit: {
          default: "mm",
        },
      },
      {
        technicalName: "width",
        slug: "largeur",
        title: "Largeur",
        type: "value",
        unit: {
          default: "mm",
        },
      },
      {
        technicalName: "length",
        slug: "longueur",
        title: "Longueur",
        type: "value",
        unit: {
          default: "mm",
        },
      },
      {
        technicalName: "weight",
        slug: "poids",
        title: "Poids",
        type: "value",
        unit: {
          default: "kg",
        },
      },
    ],
  },
  designs: {
    characteristicCategoryId: "designs",
    title: "Esthétiques",
    param: "filtres",
    characteristics: [
      {
        technicalName: "polycristallin",
        slug: "polycristallin",
        title: "Polycristallin",
        type: "boolean",
      },
      {
        technicalName: "monocristallin",
        slug: "monocristallin",
        title: "Monocristallin",
        type: "boolean",
      },
      {
        technicalName: "blackFrame",
        slug: "cadre-noir",
        title: "Cadre noir",
        type: "boolean",
      },
      {
        technicalName: "silverFrame",
        slug: "cadre-silver",
        title: "Cadre silver",
        type: "boolean",
      },
      {
        technicalName: "fullBlack",
        slug: "full-black",
        title: "Full-black",
        type: "boolean",
      },
      {
        technicalName: "transparent",
        slug: "transparent",
        title: "Transparent",
        type: "boolean",
      },
      {
        technicalName: "biGlass",
        slug: "biverre",
        title: "Biverre",
        type: "boolean",
      },
    ],
  },
  technologies: {
    characteristicCategoryId: "technologies",
    title: "Technologies",
    param: "filtres",
    characteristics: [
      {
        technicalName: "QryGroup51",
        slug: "charge-ac",
        title: "Charge AC",
        type: "boolean",
      },
      {
        technicalName: "QryGroup52",
        slug: "charge-dc",
        title: "Charge DC",
        type: "boolean",
      },
      {
        technicalName: "pType",
        slug: "p-type",
        title: "P-Type",
        type: "boolean",
      },
      {
        technicalName: "nType",
        slug: "n-type",
        title: "N-Type",
        type: "boolean",
      },
      {
        technicalName: "topcon",
        slug: "topcon",
        title: "TopCon",
        type: "boolean",
      },
      {
        technicalName: "hjt",
        slug: "hjt",
        title: "HJT",
        type: "boolean",
      },
      {
        technicalName: "backContact",
        slug: "back-contact",
        title: "Back Contact",
        type: "boolean",
      },
      {
        technicalName: "bifacial",
        slug: "bifacial",
        title: "Bi-Facial",
        type: "boolean",
      },
      {
        technicalName: "QryGroup17",
        slug: "monophase",
        title: "Monophasé",
        type: "boolean",
      },
      {
        technicalName: "QryGroup18",
        slug: "triphasé",
        title: "Triphasé",
        type: "boolean",
      },
      {
        technicalName: "monoMPPT",
        slug: "mono-mppt",
        title: "Mono MPPT",
        type: "boolean",
      },
      {
        technicalName: "multiMPPT",
        slug: "multi-mppt",
        title: "Multi MPPT",
        type: "boolean",
      },
      {
        technicalName: "microInverterOrOptimizer",
        slug: "micro-onduleurs-optimiseurs",
        title: "Micro-onduleur / Optimiseur",
        type: "boolean",
      },
      {
        technicalName: "hybrid",
        slug: "hybride",
        title: "Hybride",
        type: "boolean",
      },
      {
        technicalName: "QryGroup37",
        slug: "batterie-ac-230v",
        title: "Batterie AC (230V)",
        type: "boolean",
      },
      {
        technicalName: "QryGroup28",
        slug: "batterie-ac-400v",
        title: "Batterie AC (400V)",
        type: "boolean",
      },
      {
        technicalName: "QryGroup35",
        slug: "batterie-dc-basse-tension",
        title: "Batterie DC Basse Tension",
        type: "boolean",
      },
      {
        technicalName: "QryGroup36",
        slug: "batterie-dc-haute-tension",
        title: "Batterie DC Haute Tension",
        type: "boolean",
      },
      {
        technicalName: "QryGroup38",
        slug: "tout-en-un",
        title: "Tout en un",
        type: "boolean",
      },
      {
        technicalName: "connectorStaubli",
        slug: "connecteurs-staubli",
        title: "Connecteurs Staubli",
        type: "boolean",
      },
    ],
  },
  certifications: {
    characteristicCategoryId: "certifications",
    title: "Certifications",
    param: "filtres",
    characteristics: [
      {
        technicalName: "QryGroup6",
        slug: "certification-carbone-ppe2",
        title: "Certification carbone PPE2",
        type: "boolean",
      },
      {
        technicalName: "QryGroup48",
        slug: "certification-carbone-cre4",
        title: "Certification carbone CRE4",
        type: "boolean",
      },
      {
        technicalName: "QryGroup29",
        slug: "certification-atec",
        title: "Certification ATEC",
        type: "boolean",
      },
      {
        technicalName: "QryGroup30",
        slug: "certification-etn",
        title: "Certification ETN",
        type: "boolean",
      },
      {
        technicalName: "range_atec", // Gamme
        slug: "gamme-atec",
        title: "Certification ATEC",
        type: "boolean",
      },
      {
        technicalName: "range_etn", // Gamme
        slug: "gamme-etn",
        title: "Certification ETN",
        type: "boolean",
      },
    ],
  },
  others: {
    characteristicCategoryId: "others",
    title: "Autres",
    param: "filtres",
    characteristics: [
      {
        technicalName: "QryGroup34",
        slug: "accessoires",
        title: "Accessoires",
        type: "boolean",
      },
      {
        technicalName: "shelter",
        slug: "shelter",
        title: "Shelter",
        type: "boolean",
      },
      {
        technicalName: "screws",
        slug: "visserie",
        title: "Visserie",
        type: "boolean",
      },
      {
        technicalName: "QryGroup53",
        slug: "double-point-de-charge",
        title: "Double point de charge",
        type: "boolean",
      },
      {
        technicalName: "QryGroup23",
        slug: "monitoring",
        title: "Monitoring",
        type: "boolean",
      },
      {
        technicalName: "madeInEurope",
        slug: "fabrication-europe",
        title: "Fabrication Européenne",
        type: "boolean",
      },
      {
        technicalName: "QryGroup31",
        slug: "connecteurs-et-accessoires",
        title: "Connecteurs et Accessoires",
        type: "boolean",
      },
      {
        technicalName: "extendedWarranty",
        slug: "extension-de-garanties",
        title: "Extension de garantie",
        type: "boolean",
      },
      {
        technicalName: "QryGroup39",
        slug: "cable-4mm2",
        title: "Câble 4mm²",
        type: "boolean",
      },
      {
        technicalName: "QryGroup40",
        slug: "cable-6mm2",
        title: "Câble 6mm²",
        type: "boolean",
      },
      {
        technicalName: "QryGroup41",
        slug: "coffret-dc",
        title: "Coffret DC",
        type: "boolean",
      },
      {
        technicalName: "QryGroup42",
        slug: "coffret-ac",
        title: "Coffret AC",
        type: "boolean",
      },
      {
        technicalName: "QryGroup43",
        slug: "coffret-ac-dc",
        title: "Coffret AC/DC",
        type: "boolean",
      },
      {
        technicalName: "QryGroup46",
        slug: "cable-ac",
        title: "Câble AC",
        type: "boolean",
      },
      {
        technicalName: "QryGroup47",
        slug: "mise-a-la-terre",
        title: "Mise à la terre",
        type: "boolean",
      },
      {
        technicalName: "portrait",
        slug: "portrait",
        title: "Portrait",
        type: "boolean",
      },
      {
        technicalName: "landscape",
        slug: "paysage",
        title: "Paysage",
        type: "boolean",
      },
      {
        technicalName: "range_fix_ds_panne",
        slug: "fixation-sur-panne",
        title: "Fixation dans la panne",
        type: "boolean",
      },
      {
        technicalName: "nbUnitKit",
        slug: "nombre-unite-du-kit-dynamique",
        title: "Nombre d'unité par kit",
        type: "value",
      },
      {
        technicalName: "QryGroup50",
        slug: "outils",
        title: "Outils",
        type: "boolean",
      },
      {
        technicalName: "range_paysage", // Gamme
        slug: "gamme-paysage",
        title: "Paysage",
        type: "boolean",
      },
      {
        technicalName: "range_portrait", // Gamme
        slug: "gamme-portrait",
        title: "Portrait",
        type: "boolean",
      },
    ],
  },
  supportTypes: {
    characteristicCategoryId: "supportTypes",
    title: "Types de support",
    param: "filtres",
    characteristics: [
      {
        // name: "pitchedRoof",
        title: "Toiture inclinée",
        technicalName: "toit_incline",
        slug: "toit-incline",
        type: "boolean",
        supports: [
          {
            title: "Ardoise",
            image: ardoiseImg,
            technicalName: "ardoise",
            slug: "ardoise",
            type: "boolean",
          },
          {
            title: "Bac acier",
            image: bacAcierImg,
            technicalName: "bacacier",
            slug: "bacacier",
            type: "boolean",
          },
          {
            title: "Fibrociment",
            image: fibrocimentImg,
            technicalName: "fibrociment",
            slug: "fibrociment",
            type: "boolean",
          },
          {
            title: "Joint debout",
            image: jointDeboutImg,
            technicalName: "joint_debout",
            slug: "joint-debout",
            type: "boolean",
          },
          {
            title: "Tôle ondulée",
            image: toleOnduleeImg,
            technicalName: "tole_ondulee",
            slug: "tole-ondulee",
            type: "boolean",
          },
          {
            title: "Tuiles plates",
            image: tuilesPlatesImg,
            technicalName: "tuiles_plates",
            slug: "tuiles-plates",
            type: "boolean",
          },
          {
            title: "Tuiles canal",
            image: tuilesCanalImg,
            technicalName: "tuiles_canal",
            slug: "tuiles-canal",
            type: "boolean",
          },
          {
            title: "Tuiles mécaniques",
            image: tuilesMecaniquesImg,
            technicalName: "tuiles_mecaniques",
            slug: "tuiles-mecaniques",
            type: "boolean",
          },
        ],
      },
      {
        // name: "roofTerrace",
        title: "Toiture terrasse",
        technicalName: "toit_terasse",
        slug: "toit-terrasse",
        type: "boolean",
        supports: [
          {
            title: "Membrane Bitume",
            image: bitumeImg,
            technicalName: "membrane_bitume",
            slug: "membrane-bitume",
            type: "boolean",
          },
          {
            title: "Membrane EPDM",
            image: membraneEpdmImg,
            technicalName: "membrane_epdm",
            slug: "membrane-epdm",
            type: "boolean",
          },
          {
            title: "Membrane PVC",
            image: membranePvcImage,
            technicalName: "membrane_pvc",
            slug: "membrane-pvc",
            type: "boolean",
          },
          {
            title: "Membrane TPO",
            image: membraneTpoImg,
            technicalName: "membrane_tpo",
            slug: "membrane-tpo",
            type: "boolean",
          },
        ],
      },
      {
        // name: "floor",
        title: "Pose au sol",
        technicalName: "sol",
        slug: "sol",
        type: "boolean",
        supports: [
          {
            title: "Ombrières",
            image: ombrieresImg,
            technicalName: "ombrieres",
            slug: "ombrieres",
            type: "boolean",
          },
          {
            title: "Pieux battus",
            image: membranePvcImage,
            technicalName: "pieux_battus",
            slug: "pieux-battus",
            type: "boolean",
          },
          {
            title: "Systèmes lestés",
            image: systemesLestesImg,
            technicalName: "systemes_lestes",
            slug: "systemes-lestes",
            type: "boolean",
          },
        ],
      },
      {
        // name: "facade",
        title: "Façade",
        technicalName: "facade",
        slug: "facade",
        type: "boolean",
        supports: [],
      },
      {
        // name: "accessories",
        title: "Accessoires",
        technicalName: "accessoires",
        slug: "accessoires",
        type: "boolean",
        supports: [],
      },
    ],
  },
  supports: {
    characteristicCategoryId: "supports",
    title: "Supports",
    param: "filtres",
  },
  fixations: {
    characteristicCategoryId: "fixations",
    title: "Fixations",
    param: "filtres",
    characteristics: [
      {
        technicalName: "dynamicIntegrationKit",
        slug: "kit-dynamique",
        title: "Kit dynamique",
        type: "boolean",
      },
    ],
  },
  highlights: {
    characteristicCategoryId: "highlights",
    title: "Points forts",
    param: "filtres",
    characteristics: [
      {
        technicalName: "highlight1",
        slug: "point-fort-1",
        title: "Point fort 1",
        type: "text",
      },
      {
        technicalName: "highlight2",
        slug: "point-fort-2",
        title: "Point fort 2",
        type: "text",
      },
      {
        technicalName: "highlight3",
        slug: "point-fort-3",
        title: "Point fort 3",
        type: "text",
      },
      {
        technicalName: "highlight4",
        slug: "point-fort-4",
        title: "Point fort 4",
        type: "text",
      },
      {
        technicalName: "highlight5",
        slug: "point-fort-5",
        title: "Point fort 5",
        type: "text",
      },
    ],
  },
  technicalElements: {
    characteristicCategoryId: "technicalElements",
    title: "Eléments techniques",
    param: "filtres",
    characteristics: [
      {
        technicalName: "technicalElement1",
        slug: "element-technique-1",
        title: "Elément technique 1",
        type: "text",
      },
      {
        technicalName: "technicalElement2",
        slug: "element-technique-2",
        title: "Elément technique 2",
        type: "text",
      },
      {
        technicalName: "technicalElement3",
        slug: "element-technique-3",
        title: "Elément technique 3",
        type: "text",
      },
      {
        technicalName: "technicalElement4",
        slug: "element-technique-4",
        title: "Elément technique 4",
        type: "text",
      },
      {
        technicalName: "technicalElement5",
        slug: "element-technique-5",
        title: "Elément technique 5",
        type: "text",
      },
      {
        technicalName: "technicalElement6",
        slug: "element-technique-6",
        title: "Elément technique 6",
        type: "text",
      },
      {
        technicalName: "technicalElement7",
        slug: "element-technique-7",
        title: "Elément technique 7",
        type: "text",
      },
      {
        technicalName: "technicalElement8",
        slug: "element-technique-8",
        title: "Elément technique 8",
        type: "text",
      },
      {
        technicalName: "technicalElement9",
        slug: "element-technique-9",
        title: "Elément technique 9",
        type: "text",
      },
      {
        technicalName: "technicalElement10",
        slug: "element-technique-10",
        title: "Elément technique 10",
        type: "text",
      },
      {
        technicalName: "technicalElement11",
        slug: "element-technique-11",
        title: "Elément technique 11",
        type: "text",
      },
    ],
  },
  properties: {
    characteristicCategoryId: "properties",
    title: "Propriétés",
    param: "filtres",
    characteristics: [
      {
        technicalName: "properties56",
        slug: "fin-de-serie",
        title: "Propriété 56",
        type: "boolean",
      },
      {
        technicalName: "properties57",
        slug: "caracteristique-57",
        title: "Propriété 57",
        type: "boolean",
      },
      {
        technicalName: "properties58",
        slug: "article-sans-prix-de-vente",
        title: "Propriété 58",
        type: "boolean",
      },
      {
        technicalName: "properties59",
        slug: "nouveau",
        title: "Propriété 59",
        type: "boolean",
      },
      {
        technicalName: "properties60",
        slug: "en-promo",
        title: "Propriété 60",
        type: "boolean",
      },
      {
        technicalName: "properties61",
        slug: "caracteristique-61",
        title: "Propriété 61",
        type: "boolean",
      },
      {
        technicalName: "properties62",
        slug: "caracteristique-62",
        title: "Propriété 62",
        type: "boolean",
      },
    ],
  },
  notInFilters: {
    characteristicCategoryId: "notInFilters",
    title: "Caractéristiques non inclus dans les filtres",
    param: "filtres",
    characteristics: [
      {
        technicalName: "yield",
        slug: "rendement",
        title: "Rendement",
        type: "value",
        unit: {
          default: "%",
        },
      },
      {
        technicalName: "code",
        slug: "code",
        title: "Code",
        type: "text",
      },
      {
        technicalName: "deeeTax",
        slug: "taxe-deee",
        title: "Taxe DEEE",
        type: "text",
      },
      {
        technicalName: "endOfLife",
        slug: "caracteristique-64",
        title: "Caractéristique 64",
        type: "boolean",
      },
      {
        technicalName: "extranetDisplay",
        slug: "affichage-sur-extranet",
        title: "Affichage sur Extranet",
        type: "boolean",
      },
      {
        technicalName: "freeShipping",
        slug: "produit-sans-frais-de-port",
        title: "Produit sans frais de port",
        type: "boolean",
      },
      {
        technicalName: "guarantees",
        slug: "garanties",
        title: "Garanties",
        type: "boolean",
      },
      {
        technicalName: "homeDisplay",
        slug: "flag-remontee-sur-la-home",
        title: "Flag 'Remontée sur la home'",
        type: "boolean",
      },
      {
        technicalName: "kit",
        slug: "kit",
        title: "Kit",
        type: "boolean",
      },
      {
        technicalName: "mandatoryCharter",
        slug: "affretement-obligatoire",
        title: "Affretement obligatoire",
        type: "boolean",
      },
      {
        technicalName: "manufacturerDescription",
        slug: "description-fabricant",
        title: "Description Fabricant",
        type: "text",
      },
      {
        technicalName: "subheading",
        slug: "sous-titre",
        title: "Sous-titre",
        type: "text",
      },
      {
        technicalName: "subheadingThumbnail",
        slug: "sous-titre-vignette",
        title: "Sous Titre Vignette",
        type: "text",
      },
      {
        technicalName: "technicalElement1Href",
        slug: "lien-element-technique-1",
        title: "Lien Element technique 1",
        type: "text",
      },
      {
        technicalName: "technicalElement2Href",
        slug: "lien-element-technique-2",
        title: "Lien Element technique 2",
        type: "text",
      },
      {
        technicalName: "quantiteDecimale",
        slug: "quantite-decimale",
        title: "Quantité décimale",
        type: "boolean",
      },
      {
        technicalName: "technicalElement3Href",
        slug: "lien-element-technique-3",
        title: "Lien Element technique 3",
        type: "text",
      },
      {
        technicalName: "technicalElement4Href",
        slug: "lien-element-technique-4",
        title: "Lien Element technique 4",
        type: "text",
      },
      {
        technicalName: "technicalElement5Href",
        slug: "lien-element-technique-5",
        title: "Lien Element technique 5",
        type: "text",
      },
      {
        technicalName: "technicalElement6Href",
        slug: "lien-element-technique-6",
        title: "Lien Element technique 6",
        type: "text",
      },
      {
        technicalName: "technicalElement7Href",
        slug: "lien-element-technique-7",
        title: "Lien Element technique 7",
        type: "text",
      },
      {
        technicalName: "technicalElement8Href",
        slug: "lien-element-technique-8",
        title: "Lien Element technique 8",
        type: "text",
      },
      {
        technicalName: "technicalElement9Href",
        slug: "lien-element-technique-9",
        title: "Lien Element technique 9",
        type: "text",
      },
      {
        technicalName: "technicalElement10Href",
        slug: "lien-element-technique-10",
        title: "Lien Element technique 10",
        type: "text",
      },
      {
        technicalName: "technicalElement11Href",
        slug: "lien-element-technique-11",
        title: "Lien Element technique 11",
        type: "text",
      },
    ],
  },
};

// Add supports characteristics to the above static data object
const supportsCharacteristics =
  staticCharacteristicsDataWithoutSupports.supportTypes.characteristics.flatMap(({ supports }) =>
    supports.map(({ title, technicalName, slug, image }) => ({
      title,
      technicalName,
      slug,
      image,
      type: "boolean",
    }))
  );

export const staticCharacteristicsData = {
  ...staticCharacteristicsDataWithoutSupports,
  supports: {
    ...staticCharacteristicsDataWithoutSupports.supports,
    characteristics: supportsCharacteristics,
  },
};

// Sort characteristics by product Categories for filters.
// Modify this object to add, remove or change the order of the characteristics categories
export const staticCharacteristicsDataForFilters = {
  "kits-solaires": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.certifications,
    staticCharacteristicsData.others,
  ],
  "modules-photovoltaiques": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.certifications,
    staticCharacteristicsData.others,
  ],
  "onduleurs-solaires": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.others,
  ],
  "systemes-de-fixation": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.supportTypes,
    staticCharacteristicsData.supports,
    staticCharacteristicsData.certifications,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.others,
    staticCharacteristicsData.fixations,
  ],
  "batteries-solaires": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.others,
  ],
  "coffrets-electriques": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.others,
  ],
  "cables-et-connectiques": [staticCharacteristicsData.currently, staticCharacteristicsData.others],
  "e-mobilite": [
    staticCharacteristicsData.currently,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.others,
  ],
  "kits-plug-and-play": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.others,
  ],
  "ombrieres-et-carports": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.others,
    staticCharacteristicsData.certifications,
  ],
};

// Sort characteristics by product Categories for the comparator.
// Modify this object to add, remove or change the order of the characteristics categories
export const staticCharacteristicsDataForComparator = {
  "coffrets-electriques": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "systemes-de-fixation": [
    staticCharacteristicsData.supports,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "cables-et-connectiques": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "batteries-solaires": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "kits-solaires": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "onduleurs-solaires": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "modules-photovoltaiques": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  "e-mobilite": [
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
  ranges: [
    staticCharacteristicsData.supportTypes,
    staticCharacteristicsData.supports,
    staticCharacteristicsData.specifications,
    staticCharacteristicsData.technologies,
    staticCharacteristicsData.designs,
    staticCharacteristicsData.others,
    staticCharacteristicsData.highlights,
    staticCharacteristicsData.technicalElements,
  ],
};

export type CategorySlug =
  | keyof typeof staticCharacteristicsDataForComparator
  | keyof typeof staticCharacteristicsDataForFilters;

type StaticCharacFamily = {
  id: string;
  title: string;
  characteristics: StaticCharac[];
};

type StaticCharac = {
  technicalName: string;
  slug: string;
  title: string;
  type: "boolean" | "value" | "text";
  unit?: {
    default: string;
    [key: string]: string;
  };
};

export const staticCharacFamilies: StaticCharacFamily[] = [
  {
    id: "currently",
    title: "En ce moment",
    characteristics: [
      {
        technicalName: "properties60",
        slug: "en-promo",
        title: "En promo",
        type: "boolean",
      },
      {
        technicalName: "properties56",
        slug: "fin-de-serie",
        title: "Fin de série",
        type: "boolean",
      },
      {
        technicalName: "properties59",
        slug: "nouveau",
        title: "Nouveau",
        type: "boolean",
      },
    ],
  },
];
